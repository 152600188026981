
import * as React from 'react'
import {ConfigurationProducts, Product} from '../../../../types/types'
import {Engine, Scene, SceneEventArgs} from "react-babylonjs";
import {
    AbstractEngine, AbstractMesh,
    ArcRotateCamera, AssetsManager, BoundingSphere, Color3,
    Color4,
    HemisphericLight, Mesh,
    Nullable,
    PointLight,
    Vector3,
} from "@babylonjs/core";
//import "@babylonjs/loaders/glTF";
import {Scene as BabylonScene} from "@babylonjs/core/scene";
import {useEffect, useState} from "react";
import RokaflexLoadingScreen from "./RokaflexLoadingScreen";


type ProductRendererProps = {
    products: ConfigurationProducts
};

function ProductRendererNew(props: ProductRendererProps) {

    const [scene, setScene] = useState<BabylonScene|null>(null);
    const [camera, setCamera] = useState<ArcRotateCamera|null>(null);
    const [canvas, setCanvas] = useState<HTMLCanvasElement|null>(null);
    const [engine, setEngine] = useState<AbstractEngine|null>(null);

    const [hasBeenRendered, setHasBeenRendered] = useState<boolean>(false);

    useEffect(() => {
        if (!hasBeenRendered) {
            return;
        }
        // Product changed
        if (scene && canvas && engine) {
            scene.dispose();
            let newScene = new BabylonScene(engine);
            setScene(newScene);
            createScene(newScene, canvas);
            loadConfiguration(newScene);
            //setHasBeenRendered(false);
        }
    }, [props.products]);

    return (
        <div className="product-renderer">
            <Engine antialias adaptToDeviceRatio canvasId="product">
                <Scene
                    onSceneMount={onSceneMount}
                >
                    <></>
                </Scene>
            </Engine>
        </div>
    )

    function onSceneMount(sceneEventArgs: SceneEventArgs) {
        const { canvas, scene } = sceneEventArgs

        setCanvas(canvas);
        setEngine(scene.getEngine())

        createScene(scene, canvas)

        loadConfiguration(scene)

        scene.getEngine().runRenderLoop(() => {
            if (scene) {
                scene.render();
            }
        });
    }

    function createScene(scene: BabylonScene, canvas: HTMLCanvasElement) {
        let loadingScreen = new RokaflexLoadingScreen(canvas)

        // Set the loading screen in the engine to replace the default one
        let engine = scene.getEngine()
        engine.loadingScreen = loadingScreen
        loadingScreen.loadingUIBackgroundColor = `white`

        // engine.enableOfflineSupport = true;
        scene.clearColor = new Color4(1, 1, 1, 1)

        // TODO Added für GLB support
        scene.createDefaultEnvironment()

        // 1. Lichtquelle | Spot Rechts
        const pointLightRightVector = new Vector3(20, 20, 2500)
        const pointLightRight = new PointLight('point', pointLightRightVector, scene)
        pointLightRight.diffuse = new Color3(3, 3, 3)
        pointLightRight.specular = new Color3(3, 3, 3)
        pointLightRight.intensity = 0.2
        const pointLightRightOffsetX = -100000
        const pointLightRightOffsetY = 0

        // 2. Lichtquelle | Spot Links
        const pointLightLeftVector = new Vector3(20, 20, 2500)
        const pointLightLeft = new PointLight('point', pointLightLeftVector, scene)
        pointLightLeft.diffuse = new Color3(3, 3, 3)
        pointLightLeft.specular = new Color3(3, 3, 3)
        pointLightLeft.intensity = 0.2
        const pointLightLeftOffsetX = 100000

        // 3. Lichtquelle | Diffus von oben
        const hemiTopLightVector = new Vector3(0, -1, 0)
        const hemiTopLight = new HemisphericLight('hemi', hemiTopLightVector, scene)
        hemiTopLight.diffuse = new Color3(0.4, 0.4, 0.4)
        hemiTopLight.specular = new Color3(1, 1, 1)
        hemiTopLight.groundColor = new Color3(0.1, 0.1, 0.1)
        hemiTopLight.intensity = 0.6

        // 4. Lichtquelle | Diffus von unten
        const hemiBottomLightVector = new Vector3(0, 1, 0)
        const hemiBottomLight = new HemisphericLight('hemi', hemiBottomLightVector, scene)
        hemiBottomLight.diffuse = new Color3(0.4, 0.4, 0.4)
        hemiBottomLight.specular = new Color3(1, 1, 1)
        hemiBottomLight.groundColor = new Color3(0.2, 0.2, 0.2)
        hemiBottomLight.intensity = 0.6

        let cameraAngleInDeg: number = 30
        let camera = new ArcRotateCamera(
            'Camera',
            2 * Math.PI * (cameraAngleInDeg / 360),
            Math.PI / 2,
            2700,
            Vector3.Zero(),
            scene
        )
        camera.attachControl(canvas, false)

        scene.registerBeforeRender(() => {
            if (camera) {
                pointLightLeft.position.x = camera.position.x - pointLightLeftOffsetX
                pointLightLeft.position.y = camera.position.y
                pointLightLeft.position.z = camera.position.z
                pointLightLeft.parent = camera

                pointLightRight.position.x = camera.position.x - pointLightRightOffsetX
                pointLightRight.position.y = camera.position.y - pointLightRightOffsetY
                pointLightRight.position.z = camera.position.z
                pointLightRight.parent = camera
            }
        })
        setScene(scene);

        //camera.radius = 10
        setCamera(camera);
    }

    function loadConfiguration(scene: BabylonScene) {

        let {rooftop, transition, extras} = props.products

        let assetsManager = new AssetsManager(scene)

        if (assetsManager === null) {
            throw new Error('AssetsManager not yet initialized.')
        }

        if (props.products && rooftop.product && transition.product && extras) {
            addProductTask(rooftop.product, assetsManager)
            addProductTask(transition.product, assetsManager)

            for (let i = 0; i < props.products.extras.length; i++) {
                let extra = extras[i]
                if (extra.product !== null) {
                    addProductTask(extra.product, assetsManager)
                }
            }
        }

        assetsManager.load()

        assetsManager.onFinish = () => {
            updateProductPositions(scene)
            calculateCameraRadius(scene)
            setHasBeenRendered(true);
        }
    }

    function addProductTask(product: Product, assetsManager: AssetsManager) {
        if (assetsManager === null) {
            throw new Error('AssetsManager not yet initialized.')
        }

        // Adding mesh file
        assetsManager.addMeshTask(
            product.id,
            product.id,
            product.meshPath,
            product.meshFilename,
        )
    }

    function updateProductPositions(scene: BabylonScene) {
        //console.log("updateProductPositions")
        const delta = 50
        const height = getHeightOfConfiguration(scene)
        //console.log(height);

        let {rooftop, transition, extras} = props.products

        let target = new Vector3(0, -(height / 1.8), 0)

        if (transition.product && rooftop.product && extras) {

            //console.log("IF CONDITION")

            /*let transitionTarget = new Vector3(600, -(height / 4.1), -900)
            let transitionDelta = 800;*/

            let mesh: AbstractMesh = getProductMesh(transition.product, scene)
            mesh.setAbsolutePosition(target)
            //console.log("transition:")
            //console.log(mesh);
            //console.log("Y: " + (getHeightOfMesh(mesh) + delta))
            target.y += (getHeightOfMesh(mesh) + delta)

            for (let i = 0; i < extras.length; i++) {
                //console.log("LOOP")
                let extra = extras[i]
                if (extra.product) {
                    mesh = getProductMesh(extra.product, scene)
                }
                mesh.setAbsolutePosition(target)
                target.y += (getHeightOfMesh(mesh) + delta)
            }

            mesh = getProductMesh(rooftop.product, scene)
            mesh.setAbsolutePosition(target)
            target.y += (getHeightOfMesh(mesh) + delta)
            //console.log("rooftop:")
            //console.log(mesh);
            //console.log("Y: " + (getHeightOfMesh(mesh) + delta))
        }
    }

    function getHeightOfConfiguration(scene: BabylonScene) {
        let height = getHeightOfProductIfMeshExists(props.products.rooftop.product, scene) +
            getHeightOfProductIfMeshExists(props.products.transition.product, scene)

        for (let extra of props.products.extras) {
            height += getHeightOfProductIfMeshExists(extra.product, scene)
        }

        return height
    }

    function getHeightOfProductIfMeshExists(product: Product | null, scene: BabylonScene): number {
        if (product) {
            let productMesh = getProductMesh(product, scene)
            if (productMesh) {
                return getHeightOfMesh(productMesh)
            }
        }

        return 0
    }

    function calculateCameraRadius(scene: BabylonScene) {
        const sphere = getGlobalSphere(scene)
        if (sphere != null && camera != null) {
            camera.radius = sphere.radiusWorld * 2.5
        }
    }

    function getGlobalSphere(scene: BabylonScene): BoundingSphere | null {

        let localMeshes: AbstractMesh[] = []
        let {rooftop, transition, extras} = props.products

        if (rooftop.product && transition.product && extras) {

            localMeshes = pushProductMeshIfAvailable(rooftop.product, localMeshes, scene)
            localMeshes = pushProductMeshIfAvailable(transition.product, localMeshes, scene)

            for (let i = 0; i < props.products.extras.length; i++) {
                let extra = extras[i]
                if (extra.product !== null) {
                    localMeshes = pushProductMeshIfAvailable(extra.product, localMeshes, scene)
                }
            }

            if (localMeshes.length > 0) {
                return getBoundingSphere(localMeshes as Array<Mesh>)
            }
        }
        return null
    }

    function pushProductMeshIfAvailable(product: Product, meshes: AbstractMesh[], scene: BabylonScene): AbstractMesh[] {

        let productMesh = getProductMesh(product, scene)
        if (!productMesh) {
            throw new Error()
        }
        let parent = productMesh.parent

        let mesh: Nullable<AbstractMesh> = productMesh.clone('clone', parent, false)
        if (mesh) {
            meshes.push(mesh)
        }
        return meshes
    }

    function getProductMesh(product: Product, scene: BabylonScene): AbstractMesh {

        let mesh = scene.getMeshById(product.id)
        if (mesh === null) {
            throw new Error()
        }
        return mesh
    }

    function getHeightOfMesh(mesh: AbstractMesh) {
        const vectorsWorld = mesh.getBoundingInfo().boundingBox.vectorsWorld

        return vectorsWorld[0].subtract(vectorsWorld[3]).length()
    }

    function getBoundingSphere(meshes: Mesh[]): BoundingSphere | null {
        let mergedMesh = Mesh.MergeMeshes(meshes, true, true)
        if (mergedMesh) {
            mergedMesh.isVisible = false
            const sphere = mergedMesh.getBoundingInfo().boundingSphere
            mergedMesh.dispose()

            return sphere
        }
        return null
    }

}

export default ProductRendererNew;